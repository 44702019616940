<template>
  <v-app>
    <div id="hero">
      <v-row justify="start">
        <v-col cols="12" class="px-9">
          <v-col cols="12" md="8">
            <h1 class="h1 font-weight-bold">{{ title }}</h1>
          </v-col>
          <v-col cols="12" md="6">
            <small class="lead">{{ text }}</small>
          </v-col>
          <v-col cols="12" v-show="!user">
            <v-btn
              to="/registrations"
              class="font-weight-bold p-4 px-12"
              x-small
              color="green darken-4 white--text"
            >
              Start your health plan today
              <v-icon class="ml-12" medium>mdi-account-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="8" class="mb-3" v-show="!user">
            <small class="font-weight-bold"
              >If you already have an account, please</small
            >
            <small class="ml-2 font-weight-bold">
              <router-link :to="{ name: 'login' }">
                <span>Log in here</span>
              </router-link>
            </small>
          </v-col>
          <v-col md="8" class="mt-5">
            <img
              src="@/assets/images/png/Android_phone_icon.png"
              class="pull-left mb-12 pb-4 ml-n5"
              height="150"
            />
            <img
              src="@/assets/images/png/feature-phone-icon.png"
              class="pull-left mr-1 ml-n11 mb-8 pb-2"
              height="120"
            />
            <div class="d-inline-block">
              <small class="d-block">Very convenient, easy to use</small>
              <small class="d-block"
                >Mobile phone &amp; USSD Codes available</small
              >
            </div>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <v-row class="services">
      <v-col cols="8" md="10" class="mx-auto mt-12 mb-12">
        <div class="text-center lead mb-12">
          OpenHISA is part of Plateau State Government’s drive to improve
          citizen’s access to quality healthcare that is reliable and
          affordable. OpenHISA provides you with access to a variety of health
          plans, crafted to meet your health financing needs. Plateau State
          cares about your health!
        </div>
      </v-col>
      <v-col cols="10" md="12" class="mx-auto mb-12">
        <v-row>
          <v-col
            cols="8"
            md="3"
            class="mx-auto text-center"
            v-for="(service, index) in services"
            :key="index"
          >
            <img
              :src="service.image"
              class="rounded-lg"
              width="341"
              height="228"
              alt
            />
            <p class="mt-3">{{ service.description }}</p>
            <router-link :to="service.link.page_link">{{
              service.link.text
            }}</router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="plans">
      <v-col cols="12" class="mx-auto text-center my-6">
        <h2>Available Health Plans</h2>
      </v-col>
      <v-col cols="10" md="11" class="mx-auto mb-12">
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="(plan, index) in plans"
            :key="index"
          >
            <div class="row h-100 align-items-center">
              <div class="col-sm-6 col-md-3 d-flex align-items-center">
                <img
                  :src="plan.image"
                  :alt="plan.title"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="col-sm-12 col-md-7">
                <span class="mt-5">
                  <p class="blue--text font-weight-bold py-0 mb-0">
                    <router-link :to="plan.page_link">{{
                      plan.title
                    }}</router-link>
                  </p>
                  <small>{{ plan.description }}</small>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="subscribe">
      <v-col cols="8" md="6" class="text-center mx-auto mt-12">
        <h3>Insure your health and live a better life</h3>
        <p class="lead">Get more information today!</p>
        <v-row>
          <v-col cols="10" md="8" class="mx-auto text-center">
            <v-text-field
              label="Email"
              outlined
              color="green darken-4"
            ></v-text-field>
            <v-btn small color="green darken-4" class="white--text w-100 h-50"
              >Subscribe</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import serviceImg1 from "@/assets/images/png/a-father-with-his-children-2305212.png";
import serviceImg2 from "@/assets/images/png/piron-guillaume-kJwZxH6jins-unsplash.png";
import serviceImg3 from "@/assets/images/png/blurred-background-close-up-coffee-cup-908284.png";
import equityplanImg from "@/assets/images/png/image.png";
import formalplanImg from "@/assets/images/png/formalplanimage.png";
import informalplanImg from "@/assets/images/png/informalplanimage.png";
import privateplanImg from "@/assets/images/png/privateplanimage.png";

export default {
  name: "home",
  data() {
    return {
      chatterpal: {
        embedId: "HXa7cqxkPZCh",
        remoteBaseUrl: "https://chatterpal.me/",
        version: "7.27",
      },
      title: "Open Health Insurance System and Analytics (OpenHISA)",
      text: `
		OpenHISA is part of Plateau State Government’s drive to improve citizen’s access to quality healthcare that is reliable and affordable.
		OpenHISA provides you with access to a variety of health plans, crafted to meet your health financing needs.
		Plateau State cares about your health!`,
      services: [
        {
          image: serviceImg1,
          description: `Today you can provide a health insurance system for you and your family. Whether you are a
              business owner or a child going to school. Get started today`,
          link: {
            text: "Get started today",
            page_link: "registrations",
          },
        },
        {
          image: serviceImg2,
          description: `Our facilities are widespread and in every local government. In the case of emergency, you
              can always find a facility close to you. Health facilities closest to you`,
          link: {
            text: "Health facilities closest to you",
            page_link: "search-provider",
          },
        },
        {
          image: serviceImg3,
          description: `Don't know what to do? Chat with Mr Panshak today, ask questions on how to get a health
              plan today or talk with an agent. Chat with him now`,
          link: {
            text: "Chat with him now",
            page_link: "#",
          },
        },
      ],
      plans: [
        {
          image: equityplanImg,
          title: "Equity Health Plan",
          description: `Under five, pregnant women, elderly, physically & mentally challenged & the Internally
              Displaced`,
          page_link: "registrations/equity",
        },
        {
          image: informalplanImg,
          title: "Informal Health Plan",
          description: `Accessible to all residents not covered by other health plans`,
          page_link: "registrations/informal",
        },
        {
          image: formalplanImg,
          title: "Formal Health Plan",
          description: `Public-sector employees and organized private sector employees`,
          page_link: "registrations/formal",
        },
        {
          image: privateplanImg,
          title: "Private Health Plan",
          description: `Variety of packages providing extra healthcare services`,
          page_link: "registrations/private",
        },
      ],
    };
  },
  created() {
    /** Initalize chatterpal website avatar plugin
     * Chatterpal: a new “artificial intelligence” backed smart chat automation technology that can instantly double conversions, sales and leads.
     * Learn more here: https://www.youtube.com/watch?v=NkLp7tjNDr0
     */
    // Only call chatterpal constructor when loaded
    if (window["ChatPal"]) {
      const { embedId, remoteBaseUrl, version } = this.chatterpal;
      new window.ChatPal({
        embedId,
        remoteBaseUrl,
        version,
      });
    }
  },
  destroyed() {
    // TODO: optimize way to hide avatar when component is detroyed
    this.chatterpal = null;
  },
  computed: {
    ...mapGetters(["user", "userGroup"]),
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
#hero {
  width: 100% !important;
  background: url("~@/assets/images/jpg/Nigerian Family.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.services {
  background: honeydew;
}
.plans {
  background: whitesmoke;
}
.subscribe {
  margin-bottom: 100px;
}
</style>
